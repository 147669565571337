.react-calendar { 
  width: 100%;
  background-color: #fff;
  color: #222;
  font-family: 'Lato', sans-serif;
  line-height: 1.125em;
  border: none;
 }
 /* react-calendar__navigation__next2-button */
 .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button{
  visibility: hidden;
 }

 .react-calendar__month-view__days__day,.react-calendar__month-view__weekdays {
   height: 50px; /* set the desired height */
 }

 .react-calendar__navigation__arrow{
  font-size: 20px;

 }

 .react-calendar__navigation button {
  color: black;
  height: 66px;
  min-width: 79pxpx;
  font-weight: 600;
  background: none;
  font-size: 17px;

 }


 .react-calendar__navigation button:enabled:hover,
 .react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
 }
 .react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
 }
 
 abbr[title] {
  text-decoration: none;
 }


 .react-calendar__month-view__days__day--weekend {
  color: #ACB9C0;
 }



 .react-calendar__tile:enabled:hover,
 .react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: black;
  border-radius: 50px;
 }
 .react-calendar__tile--now {
  background: #6f48eb33;
  border-radius: 50px;
  font-weight: bold;
  color: black;
 }
 .react-calendar__tile--now:enabled:hover,
 .react-calendar__tile--now:enabled:focus {
  background: #6f48eb33;
  border-radius: 50px;
  font-weight: bold;
  color: black;
 }
 .react-calendar__tile--hasActive:enabled:hover,
 .react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
 }
 .react-calendar__tile--active {
  background: linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 102.06%);
  border-radius: 50px;
  font-weight: bold;
  color: white;
 }
 .react-calendar__tile--active:enabled:hover,
 .react-calendar__tile--active:enabled:focus {
  background: linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 102.06%);
  color: white;
 }
 .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
 }
 .react-calendar__tile--range {
  background: #f8f8fa;
  color: black;
  border-radius: 0;
 }
 .react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 102.06%);
  color: white;
 }

 .react-calendar__tile--rangeEnd {
  border-radius: 50px;


  /* Ellipse 26 */


background: linear-gradient(177.88deg, #AFD462 1.79%, #6BC698 49.97%, #0FB1E2 102.06%);

  color: white;
 }

