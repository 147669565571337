@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Karla:wght@500;600;700;800&family=Lato:wght@300;400;700;900&display=swap');


@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}



.gradient:hover {
  fill: url('#gradient-example');
}




body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}



.calendar {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
}

.calendar-tile {
  background-color: #f0f0f0;
  border-radius: 50%;
}